import React from "react";

import {Save}     from "react-bootstrap-icons";

import Spinner from "components/shared/Spinner";
import CountrySelect from "components/shared/CountrySelect";

const GeneralSettings = ({settings, save, handleInput, handleFileInput, formData, logo, uploadProgress, settingsChanged}) => {

	return (
		<>
            <header>
                <h2 className="py-4">
                    Allgemeine Einstellungen
                    <button className="btn btn-success btn-sm float-right" disabled={!settingsChanged} onClick={settingsChanged ? event => save(event) : null}>
						<Save /> Speichern
                    </button>
                </h2>
            </header>
			{formData ? 
				<section className="grid half-half">
					<div className="field-wrapper">
						<label htmlFor="invoiceCompany">Firma</label>
						<input type="text" className="form-control" name="invoiceCompany" value={formData.invoiceCompany} onChange={event => handleInput(event)} />
					</div>
					<div className="field-wrapper">
						<label htmlFor="city">Stadt</label>
						<input type="text" className="form-control" name="city" value={formData.city} onChange={event => handleInput(event)}/>
					</div>
					<div className="field-wrapper">
						<label htmlFor="zip">PLZ</label>
						<input type="text" className="form-control" name="zip" value={formData.zip} onChange={event => handleInput(event)} />
					</div>
					<div className="field-wrapper">
						<label htmlFor="state">State</label>
						<input type="text" className="form-control" name="state" value={formData.state} onChange={event => handleInput(event)} />
					</div>
					<div className="field-wrapper">
						<label htmlFor="website">Website</label>
						<input type="text" className="form-control" name="website" value={formData.website} onChange={event => handleInput(event)} />
					</div>
					<div className="field-wrapper">
						<label htmlFor="account">Website</label>
						<input type="text" className="form-control" name="account" value={formData.account} onChange={event => handleInput(event)} />
					</div>
					<div className="field-wrapper">
						<label htmlFor="countryCode">Ländercode</label> <br />
						<CountrySelect value={formData.countryCode} onChange={event => handleInput(event)} name="countryCode" style={{
							    width: "100%",
								height: "calc(1.5em + 0.75rem + 2px)",
								padding: "0.375rem 0.75rem"
						}}/>
					</div>
					<div className="field-wrapper grid seventy-thirty" style={{padding: "0"}}>
						<div>
							<label htmlFor="">Logo</label>
							<div className="custom-file">
								<input type="file" className="custom-file-input" name="files.logo" accept="image/gif, image/jpeg, image/png" onChange={event => handleFileInput(event)}/>
								<label htmlFor="logo" className="custom-file-label" data-browse="Datei auswählen">{logo ? logo.name : "Bitte Datei auswählen..."}</label>
								{
								uploadProgress > 0 ? 
									<>
										<p style={{marginTop: "20px", marginBottom: "10px"}}>Hochladen...</p>
										<div className="progress">
											<div className="progress-bar bg-primary" role="progressbar" style={{width: `${uploadProgress}%`}}  aria-valuenow={uploadProgress} aria-valuemin="0" aria-valuemax="100">{uploadProgress}%</div>
										</div>
									</>
								: null
								}
							</div>
						</div>
						<div>
							<p>Aktuelles Logo</p>							
							{settings.logo ? <img src={process.env.REACT_APP_BACKEND_URL + settings.logo.url} alt="" style={{maxWidth: "100%"}} /> : <p>Noch kein Logo hochgeladen</p>}
						</div>
					</div>
				</section>
			: <Spinner />}
		</>
	);
};

export default GeneralSettings;