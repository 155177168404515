let Currency = "EUR";

const CurrencyToSymbol = currency => {
	switch (currency) {
		case "EUR":
			return "€";
		case "USD":
			return "$";
		case "JPY":
		case "CNY":
			return "¥";
		case "BGP":
			return "£";
			
		default:
			return currency;
	}
};

const PriceFormatter = (price, currency = null) => {
	const currencySymbol = CurrencyToSymbol(currency ? currency : Currency);
	const label          = currencySymbol.length === 1 ? currencySymbol : ` ${currencySymbol}`;
	return `${(Math.round(price * 100) / 100).toLocaleString("de-DE", {minimumFractionDigits: 2})}${label}`;
};

const ChangeCurrency = currency => {
	Currency = currency;
}

export {ChangeCurrency};
export default PriceFormatter;
