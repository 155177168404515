import React from "react";

import {Modal}      from "react-bootstrap";
import {CircleFill} from "react-bootstrap-icons";

import SingleMemberList     from "../SingleMemberList";
import EmailFormatter       from "../../../../formatters/EmailFormatter";
import PhoneNumberFormatter from "../../../../formatters/PhoneNumberFormatter";
import FlagFormatter        from "formatters/FlagFormatter";

const InfoScreen = ({currentUser, show, setShow, save, onHide}) => {
	console.log("tags", currentUser?.cv_tags);
    return (
        <Modal size="xl" scrollable={true} show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
	                Mitgliedsakte
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding : "0 4rem"}}>

                <section>
                    <section className="row">
                            <section className="col-md-3">
                                <article className="avatar">
                        			{currentUser?.avatar ?
										<img
											src={process.env.REACT_APP_BACKEND_URL + currentUser?.avatar?.url}
											alt={currentUser?.avatar?.name||"no name"}
											className="image__avatar"
										/>
                        			: <div className="no-avatar"><span>{currentUser?.firstName.charAt(0)}{currentUser?.lastName.charAt(0)}</span></div>}
                                </article>
                            </section>
                        <section className="col-md-9" style={{
                            display: "flex",
                            alignItems : "center",
                            padding : currentUser?.avatar ? "initial" : "4rem 0"
                        }}>
	                        <section>
                                <span style={{fontSize : "1.5rem", color: "#aaa"}}>{currentUser?.company}</span><br />
                                <h1 style={{fontWeight : "100"}}>
                                    {currentUser?.firstName} {currentUser?.lastName}
                                    {currentUser && currentUser.aka_title ? `, ${currentUser.aka_title}` : ""}
                                </h1>
                            </section>
                        </section>
                    </section>
					<section className="row">
                        <section className="col-md-3 text-right text-secondary small">auf Webseite</section>
						<section className="col-md">
							<CircleFill className={currentUser?.onWebsite ? "text-success" : "text-danger"} style={{fontSize : "0.8rem"}} />
						</section>
					</section>
                    <section className="row">
                        <section className="col-md-3 text-right text-secondary small">Id</section>
                        <section className="col-md-9">
                            <code>{currentUser?.id}</code>
                        </section>
                    </section>
                    <section className="row">
                        <section className="col-md-3 text-right text-secondary small">Name</section>
                        <section className="col-md-9">
                            {currentUser?.firstName} {currentUser?.lastName}
                        </section>
                    </section>
                    <section className="row">
                        <section className="col-md-3 text-right text-secondary small">Adresse</section>
                        <section className="col-md-9">
                            {currentUser?.street}<br />
                            {currentUser?.zip} {currentUser?.city}
							{currentUser?.country ?
								<p className="my-2">
									{FlagFormatter(currentUser.country)}
								</p>
							: null}
                        </section>
                    </section>
                    <section className="row">
                        <section className="col-md-3 text-right text-secondary small">Beschreibung</section>
                        <section className="col-md-9">
                            {currentUser?.description}
                        </section>
                    </section>
                    <section className="row">
                        <section className="col-md-3 text-right text-secondary small">Kontakt</section>
                        <section className="col-md-9">
                            {currentUser && currentUser.email && EmailFormatter(currentUser.email)}
                            <br />
                            {currentUser && currentUser.phone && PhoneNumberFormatter(currentUser.phone)}
                        </section>
                    </section>
                    <section className="row">
                        <section className="col-md-3 text-right text-secondary small">Email der Organisation</section>
                        <section className="col-md-9">
                            {currentUser && currentUser.companyEmail && EmailFormatter(currentUser.companyEmail)}
                        </section>
                    </section>

                    <section className="row">
                        <section className="col-md-3 text-right text-secondary small">Tags</section>
                        <section className="col-md-9">
                            <div className="react-tags react-tags--only-show">
                                <div className="react-tags__selected">
                                    {currentUser && currentUser?.cv_tags ? currentUser.cv_tags.map((tag, i) => (
                                        <button className="react-tags__selected-tag" key={"tag_"+i}>
                                            <span className="react-tags__selected-tag-name" >{tag}</span>
                                        </button>

                                    )) : null}
                                </div>
                            </div>
                        </section>
                    </section>
                </section>

				<section>
					<hr />
				</section>

                <section>
                    <section className="row">
                        <section className="col-md-3 text-right text-secondary small">Mitgliedschaft</section>
                        <section className="col-md-9">
                            {currentUser?.cv_plan?.Name}
                        </section>
                    </section>
                    <section className="row">
                        <section className="col-md-3 text-right text-secondary small">Zahlart</section>
                        <section className="col-md-9">
                            {currentUser?.cv_payment_method?.Name}
                        </section>
                    </section>
                </section>
				<section>
					<hr />
				</section>
                <section className="container">
                    <SingleMemberList user={currentUser} />
                </section>
            </Modal.Body>

        </Modal>
    );
}

export default InfoScreen;
