import React, {useEffect, useState, useContext, useCallback} from "react";
import axios from "axios";

import {Paypal}                 from "react-bootstrap-icons";
import {useAlert}               from "react-alert";
import {NavLink, Routes, Route} from "react-router-dom";

import UserContext from "components/UserContext";
import usePermission from "components/hooks/usePermission";

import GeneralSettings from "./components/GeneralSettings";
import TechnicalSettings from "./components/TechnicalSettings";
import InvoiceSettings from "./components/InvoiceSettings";

const PayPal = ({type}) => {
	const {permissions}           = useContext(UserContext);
	const alert                   = useAlert();
	const [ready, setReady]       = useState(false);
	const [settings, setSettings] = useState({});
	const [formData, setFormData] = useState({
		account: "",
        clientID: "",
        secret: "",
        invoiceEmail: "",
        invoiceFirstName: "",
        invoiceLastName: "",
        invoiceStreet: "",
        city: "",
        state: "",
        invoiceAdminArea2: "",
        zip: "",
        countryCode: "",
        website: "",
        taxID: "",
        sandboxAccount: "",
        sandboxClientID: "",
        sandboxSecret: "",
        sandbox: true,
        invoiceNumber: 1,
        invoiceCompany: "",
        invoicePhone: "",
        defaultDueDays: 0,
        invoiceCurrency: ""
	});
	const [logo, setLogo] = useState(null);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [settingsChanged, setSettingsChanged] = useState(false);
	const canUseModule = usePermission("paypal-settings", "find");

	useEffect(() => {
		setFormData(data => {return {...data, ...settings}});
	}, [settings]);

	const load = useCallback(() => {
		axios.get("/paypal-settings").then(response => {
		  setSettings(response.data);
		}).catch(error => alert.error(`Fehler beim Laden der PayPal-Einstellungen: ${error}`));
  	}, [setSettings, alert]);

	useEffect(() => {
		if (typeof permissions.application !== "undefined")
			setReady(true);
	}, [permissions]);

	useEffect(() => {
		if (!ready)
			return;
		load();
	}, [ready, alert, load]);

	const handleInput = event => {
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		setFormData({ ...formData, [event.target.name]: value });
		setSettingsChanged(true);
	}

	const handleFileInput = event => {
		setLogo(event.target.files[0])
		setSettingsChanged(true);
	}

	const save = useCallback(event => {
		event.preventDefault();
		const data = new FormData();
        data.append('files.logo', logo);
        data.append('data', JSON.stringify(formData));
		
		axios.put("/paypal-settings", data, { 
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: (uploadEvent) => {
				if (logo === null) 
					return;		
				setUploadProgress(Math.round((100 * uploadEvent.loaded) / uploadEvent.total));
			}
		}).then(() => {
			setLogo(null);
			setUploadProgress(0);
			setSettingsChanged(false);
			alert.success("Erfolgreich gespeichert");
			load();
		}).catch(error => alert.error(`Fehler beim Speichern der PayPal-Einstellungen: ${error}`));
	}, [formData, alert, logo, load]);

	if (!ready || !canUseModule)
		return null;

	if (type === "nav")
		return <NavLink className={({isActive}) => isActive ? "active" : ""} to="/PayPal"><Paypal /> <span>PayPal</span></NavLink>

	return (
		<>
			<nav>
				<ul className="nav nav-tabs">
					<li className="nav-item"><NavLink end className="nav-link" to="/PayPal">Allgemein</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="gInvoice-Settings">Rechnung</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="gTechnical-Settings">Technisch / PayPal</NavLink></li>
				</ul>
			</nav>
			<Routes>
				<Route index element={ 
					<GeneralSettings 
						formData={formData} 
						setFormData={setFormData} 
						handleInput={handleInput} 
						logo={logo} 
						save={save} 
						handleFileInput={handleFileInput} 
						settings={settings} 
						uploadProgress={uploadProgress} 
						settingsChanged={settingsChanged} 
					/>} 
				/>
				<Route exact path="gInvoice-Settings" element={ 
					<InvoiceSettings 
						formData={formData} 
						setFormData={setFormData} 
						handleInput={handleInput} 
						settingsChanged={settingsChanged} 
						save={save} 
					/>} 
				/>
				<Route exact path="gTechnical-Settings" element={ 
					<TechnicalSettings 
						formData={formData} 
						setFormData={setFormData} 
						handleInput={handleInput} 
						settingsChanged={settingsChanged}
						save={save} 
					/>}
				/>
			</Routes>
		</>
	);
};

export default PayPal;