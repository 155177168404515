import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";

import ReactTags     from "react-tag-autocomplete";
import {Modal, Form} from "react-bootstrap";
import {useAlert}    from "react-alert";

import Select        from "components/shared/Select";
import CountrySelect from "components/shared/CountrySelect";
import { method } from "lodash";
import PriceFormatter from "formatters/PriceFormatter";

const InitialFormData = {
	aka_title         : "",
	firstName         : "",
	lastName          : "",
	company           : "",
	address           : "",
	country           : "CH",
	description       : "",
	email             : "",
	companyEmail      : "",
	phone             : "",
	cv_plan           : "",
	cv_payment_method : "",
	avatar            : null,
	cv_tags           : [],
	street            : "",
	zip               : "",
	city              : "",
	onWebsite         : false,
};

const EditScreen = ({currentUser, show, setShow, paymentMethods, paymentPlans, allTags, callback}) => {
	const alert                   = useAlert();
	const [avatar, setAvatar]     = useState(null);
	const [cv_tags, setTags]      = useState([]);
	const [formData, setFormData] = useState(InitialFormData);

	useEffect(() => {
		if (!currentUser) {
			setFormData(InitialFormData);
			return;
		}
		setFormData((data) => {
			return { ...data, ...currentUser, cv_payment_method : currentUser?.cv_payment_method?.id, cv_plan : currentUser?.cv_plan?.id };
		});
		setAvatar(currentUser.avatar);

		setTags(
			currentUser.cv_tags?.map((tag, index) => ({ id: index, name: tag }))
		);
	}, [currentUser]);

	const handleInput = useCallback(
		(event) => {
			const data              = {...formData};
			const value             = event.target.type === "checkbox" ? event.target.checked : event.target.value;
			data[event.target.name] = value;
			setFormData(data);
		},
		[formData]
	);

	const hiddenFileInput = React.useRef(null);
	const handleAvatarUpload = () => {
		hiddenFileInput.current.click();
	};

	const onChangeAvatar = (event) => {
		let reader    = new FileReader();
		reader.onload = (event) => {
			document.querySelector(".currentAvatar__image").src = event.target.result;
		};
		reader.readAsDataURL(event.target.files[0]);
		console.log(event.target.files[0]);
		setAvatar(event.target.files[0]);
	};

	const emptyAvatar = () => {
		setAvatar(null);
	};

	const tagOnDelete = (i) => {
		const tags = cv_tags;
		tags.splice(i, 1);
		setTags(tags);
	};

	const tagOnAdd = (tag) => {
		setTags([...cv_tags, tag]);
	};

	const save = useCallback((event) => {
		event.preventDefault();

		const empty = val => !val || val == "";

		if (empty(formData.firstName)) {
			alert.error("Das Feld 'Vorname' muss ausgefüllt sein.");
			return;
		}
		if (empty(formData.lastName)) {
			alert.error("Das Feld 'Nachname' muss ausgefüllt sein.");
			return;
		}
		if (empty(formData.country)) {
			alert.error("Das Feld 'Land' muss ausgefüllt sein.");
			return;
		}
		if (empty(formData.cv_payment_method)) {
			alert.error("Das Feld 'Zahlungsmethode' muss ausgefüllt sein.");
			return;
		}
		if (empty(formData.cv_plan)) {
			alert.error("Das Feld 'Mitgliedschaft' muss ausgefüllt sein.");
			return;
		}

		const data = new FormData();
		data.append("files.avatar", avatar);
		data.append("data", JSON.stringify(formData));

		if (currentUser) {
			axios.put(`/members/${currentUser._id}`, data, {headers: { "Content-Type": "multipart/form-data" }}).then(response => {
				//console.log(response);
				callback();
				setShow(false);
				alert.success("Das Mitglied wurde erfolgreich bearbeitet!");
			}).catch((error) => {
				console.log(error);
			});
		} else {
			axios.post("/members", {...formData}).then(() => {
				alert.success(`Das Mitglied ${formData.firstName} ${formData.lastName} wurde erfolgreich angelegt.`);
				callback();
				setShow(false);
			}).catch(error => {
				console.log(error.response.data);
				alert.error(`Fehler beim Anlegen des Mitglieds: ${error}`);
			});
		}
	}, [formData, alert, callback, avatar, setShow, currentUser]);

	return (
		<Modal size="lg" scrollable={true} show={show} onHide={() => setShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title>
					Benutzer{" "} {currentUser ? "Mitgliedsakte bearbeiten" : "Mitgliedsakte anlegen"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="edit-modal">
					<section className="row">
						<section className="col-md-3">Avatar</section>
						<section className="col-md-9">
							{avatar && (
								<div className="currentAvatar">
									<div className="currentAvatar__close" onClick={emptyAvatar}>&times;</div>
									<img src={process.env.REACT_APP_BACKEND_URL + formData.avatar?.url}
										alt={currentUser?.avatar?.name || "no name"}
										className="currentAvatar__image" />
								</div>
							)}
							{!avatar && (
								<>
									<button onClick={handleAvatarUpload} className="btn btn-outline-primary">
										Bild auswählen
									</button>
									<input type="file" onChange={onChangeAvatar} name="avatar" className="avatar__input" ref={hiddenFileInput} />
								</>
							)}
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">auf Webseite ausgeben</section>
						<section className="col-md-9">
							<Form.Check type="switch" name="onWebsite" onChange={handleInput} checked={formData.onWebsite} label="" id="active" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Akademischer Titel</section>
						<section className="col-md-9">
							<input className="form-control" value={formData["aka_title"]} onChange={handleInput} type="text" name="aka_title" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Vorname</section>
						<section className="col-md-9">
							<input className="form-control" value={formData.firstName} onChange={handleInput} type="text" name="firstName" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Nachname</section>
						<section className="col-md-9">
							<input className="form-control" value={formData.lastName} onChange={handleInput} type="text" name="lastName" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Organisation</section>
						<section className="col-md-9">
							<input className="form-control" value={formData.company} onChange={handleInput} type="text" name="company" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Straße</section>
						<section className="col-md-9">
							<input type="text" name="street" value={formData.street} className="form-control" onChange={handleInput} />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Postleitzahl</section>
						<section className="col-md-2">
							<input type="text" name="zip" value={formData.zip} className="form-control" onChange={handleInput} />
						</section>
						<section className="col-md-1">
							<span style={{ verticalAlign: "middle" }}>Stadt</span>
						</section>
						<section className="col-md-6">
							<input type="text" name="city" value={formData.city} className="form-control" onChange={handleInput} />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Land</section>
						<section className="col-md-9">
								<CountrySelect value={formData.country} onChange={handleInput} name="country" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Tags</section>
						<section className="col-md-9">
							<ReactTags allowNew={true} tags={cv_tags} suggestions={allTags} onDelete={tagOnDelete} onAddition={tagOnAdd} placeholderText={"Tag hinzufügen"} />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Beschreibung</section>
						<section className="col-md-9">
							<textarea className="form-control" value={formData.description} onChange={handleInput} name="description" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Email</section>
						<section className="col-md-9">
							<input className="form-control" value={formData.email} onChange={handleInput} type="text" name="email" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Email der Organisation</section>
						<section className="col-md-9">
							<input className="form-control" value={formData.companyEmail} onChange={handleInput} type="text" name="companyEmail" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Telefon</section>
						<section className="col-md-9">
							<input className="form-control" value={formData.phone} onChange={handleInput} type="text" name="phone" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Mitgliedschaft</section>
						<section className="col-md-9">
							<Select data={paymentPlans?.map(plan => ({...plan, Name : `${plan.Name} (${PriceFormatter(plan.Price, plan.currency)})`}))} onChange={handleInput} current={formData.cv_plan} member="Name" name="cv_plan" />
						</section>
					</section>
					<section className="row">
						<section className="col-md-3">Bezahlmethode</section>
						<section className="col-md-9">
							<Select data={paymentMethods} current={formData.cv_payment_method} onChange={handleInput} member="Name" name="cv_payment_method" />
						</section>
					</section>
				</section>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={event => {event.preventDefault(); setShow(false);}}>Abbrechen</button>
				<button className="btn btn-success" onClick={save}>{currentUser ? "Speichern" : "Anlegen"}</button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditScreen;
