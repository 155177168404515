import React, {useCallback, useEffect, useState, useMemo} from "react";
import axios from "axios";

import {BookmarkCheck, FileEarmarkArrowDown, CheckCircle, XCircle} from "react-bootstrap-icons";
import {useAlert}      from "react-alert";

import Spinner        from "../../../components/shared/Spinner";
import SortableTable  from "../../../components/shared/SortableTable";
import DateFormatter  from "../../../formatters/DateFormatter";
import PriceFormatter from "../../../formatters/PriceFormatter";

import PaymentsReport, {ExportType} from './reports/PaymentsReport';

const SingleMemberList = ({user = undefined}) => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading]   = useState(true);
    const alert                   = useAlert();

    const load = useCallback(() => {
        if(!user)
			return;

        axios.get(`/cm-zahlungens?cv_member=${user.id}`).then(response => {
			setPayments(response.data);
			setLoading(false);
		}).catch(error => alert.error(`Fehler beim Laden der Zahlungsdetails: ${error}`));
        
    }, [setPayments, setLoading, user, alert]);

    useEffect(() => {
        load();
    },[load]);

    const markAsPayed = useCallback(payment => {
        axios.put(`/cm-zahlungens/${payment.id}`, {
            payed: !payment?.payed,
        }).then(response => {
            setLoading(true);
            load();
            if(response.data.payed) {
                alert.success('Rechnung wurde bezahlt!');
            } else {
                alert.info('Rechnung wurde als unbezahlt markiert!');
            }
        }).catch(error=>{
            console.log(error);
            alert.error('Es gab ein Problem!');
        });
    }, [load, alert]);

    const headers = useMemo(() => [
        {
            label : "Id",
            member : "id",
            width: "5%",
	        sortable: false
        },
        {
            label: "Datum",
            member: "createdAt",
            formatter : DateFormatter
        },
        {
            label: "Beitrag",
            member : "cv_plan.Price",
            sortable: false,
            width: "10%",
            formatter : PriceFormatter
        },
        {
            label: "Mitgliedschaft",
            member: "cv_plan.Name",
        },
        {
            label: "Zahlart",
            member: "cv_payment_method.Name"
        },
        {
            label: "Bezahlt",
	        sortable: false,
            padding : "0.8rem",
            member: null,
            width : "8%",
            functional : ({member}) => (
				<>
					{member.payed ?
						<CheckCircle className="svg-show text-success" />
					: <XCircle className="svg-show text-danger" />}
				</>
			)
        },
        {
            label: "",
            member: null,
            sortable: false,
            width: "14%",
            functional : ({member}) => {
                return (
                    <button className="btn" onClick={() => markAsPayed(member)}><BookmarkCheck /></button>
                )
            }
        }
    ], [markAsPayed]);

    if (payments.length <= 0)
        return <></>;

    return(
        <>
            <h2 style={{marginTop : "4rem", display: "flex", alignItems : "middle"}}>
                Zahlungshistorie
                <button className="btn btn-outline-primary" style={{marginLeft: "8rem", marginRight: "1rem"}} onClick={() => PaymentsReport(payments, ExportType.ALL, user)}>
                    <FileEarmarkArrowDown />
					PDF Export
                </button>
            </h2>
			{!loading ? 
	            <section className="my-4">
                    <SortableTable headers={headers} data={payments}  />
                </section>
			: <Spinner /> }
        </>
    );
}

export default SingleMemberList;
