import React, {useState, useEffect, useCallback, useRef} from "react";
import axios from "axios";

import {useAlert} from "react-alert";

import {createReactEditorJS} from "react-editor-js";
import list                  from "@editorjs/list";
import header                from "@editorjs/header"
import paragraph             from "@editorjs/paragraph";

import {Modal} from "react-bootstrap";

const EditorJS = createReactEditorJS();

const SignatureEdit = ({signature = null, show, setShow, callback}) => {
	const alert           = useAlert();
	const editor          = useRef();
	const [name, setName] = useState(null);

	useEffect(() => {
		if (!signature) {
			setName("");
			return;
		}
		setName(signature.name);
	}, [signature]);

	const save = useCallback(async () => {
		const editorContent = JSON.stringify(await editor.current.save());
		if (signature) {
			axios.put(`/cv-signatures/${signature._id}`, {
				name,
				content : editorContent
			}).then(() => {
				setShow(false);
				setName("");
				callback();
				alert.success("Signatur erfolgreich gespeichert");
			}).catch(error => alert.error(`Fehler beim Speichern der Signatur: ${error}`));
		} else {
			axios.post("/cv-signatures", {
				name,
				content : editorContent
			}).then(() => {
				setShow(false);
				setName("");
				callback();
				alert.success("Signatur erfolgreich angelegt");
			}).catch(error => {console.log(error.response); alert.error(`Fehler beim Anlegen der Signatur: ${error}`); });
		}
	}, [alert, callback, name, signature, setShow]);

	return (
		<Modal size="xl" onHide={() => setShow(false)} show={show}>
			<Modal.Header>
				<Modal.Title>{signature ? `Signatur '${signature.name}' bearbeiten` : "Signatur anlegen"}</Modal.Title>
			</Modal.Header>	
			<Modal.Body>
				<section className="grid thirty-seventy">
					<label>Name</label>
					<input type="text" name="name" className="form-control" value={name} onChange={event => setName(event.target.value)} />
					<label>Inhalt</label>
					<section className="editor">
						<EditorJS data={signature ? JSON.parse(signature.content): null}  onInitialize={instance => editor.current = instance} tools={{
							list : {class : list},
							paragraph : {
								class         : paragraph,
								inlineToolbar : true
							},
							header
						}} />
					</section>
				</section>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => setShow(false)}>Abbrechen</button>
				<button className="btn btn-primary" onClick={save}>Speichern</button>
			</Modal.Footer>
		</Modal>
	);
};

export default SignatureEdit;