import PDF, {Align} from "../../../../utils/PDF";

const ProposalsReport = (users, payments) => {

    const doc = new PDF({
        top    : 20,
        right  : 15,
        left   : 15,
        bottom : 15
    });

    doc.h1("marvolo. Report über Mitgliedsanträge", Align.CENTER, 0);

    doc.vspace(10).tabular({
        head : [[
            "Vorname",
            "Nachname",
            "Organisation",
            "E-Mail",
            "Mitgliedschaft"
        ]],
        body: users.map(user => {
			return [
				user.firstName,
				user.lastName,
				user.company,
				user.email,
				user.cv_plan?.Name
			];
		}),
        styles : {
            fontSize : 8
        }
    });

    doc.show("marvolo-pdf_proposalslist.pdf");
};

export default ProposalsReport;
