import React, {useState, useEffect, useMemo, useCallback} from "react";
import axios from "axios";

import {VectorPen, Pencil, Trash} from "react-bootstrap-icons";
import {useAlert}  from "react-alert";

import SortableTable from "components/shared/SortableTable";
import YesNoModal    from "components/shared/YesNoModal";

import SignatureEdit from "../../modals/SignatureEdit";

const Signatures = () => {
	const alert = useAlert();
	const [signatures, setSignatures] = useState([]);
	const [signature, setSignature]   = useState(null);
	const [showEdit, setShowEdit]     = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [ready, setReady]           = useState(false);

	const load = useCallback(() => {
		axios.get("/cv-signatures").then(response => {
			setSignatures(response.data);
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden der Signaturen: ${error}`));
	}, [alert]);
	
	useEffect(() => {
		load();
	}, [load]);

	const headers = useMemo(() => [
		{
			label  : "Id",
			member : "id",
			width  : "5%"
		},
		{
			label  : "Name",
			member : "name"
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			width      : "90px",
			functional : ({member}) => {
				return (
					<>
						<button className="btn" onClick={() => {setSignature(member); setShowEdit(true);}}><Pencil /></button>
						<button className="btn" onClick={() => {setSignature(member); setShowDelete(true);}}><Trash /></button>
					</>
				);
			}
		}
	], []);

	const deleteSignature = () => {
		axios.delete(`/cv-signatures/${signature._id}`).then(() => {
			alert.success(`Die Signatur '${signature.name}' wurde erfolgreich gelöscht.`);
			setSignature(null);
			load();
		}).catch(error => alert.error(`Fehler beim Löschen der Signatur '${signature.name}': ${error}`));
	};

	return (
		<>
			<header style={{marginBottom: "2rem"}}>
				<h2 className = "py-4">Signaturen für Mailings <button className = "btn btn-success btn-sm float-right"	onClick={() => {setSignature(null); setShowEdit(true);}} ><VectorPen className = "big" /> Neue Signatur anlegen</button>
				</h2>
			</header>
			{ready ? 
				<SortableTable headers={headers} data={signatures} />
				: ""
			}
			<SignatureEdit show={showEdit} setShow={setShowEdit} signature={signature} callback={() => load()} />
			<YesNoModal title="Signatur löschen" text={`Möchten Sie die Signatur '${signature?.name}' wirklich löschen?`} callback={deleteSignature} show={showDelete} setShow={setShowDelete} />
		</>
	);
};

export default Signatures;