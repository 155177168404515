import React, {useState, useCallback, useEffect} from "react";
import axios from "axios";

import {NavLink, Routes, Route} from "react-router-dom";
import {PersonPlusFill}         from "react-bootstrap-icons";
import {useAlert}               from "react-alert";

import Spinner from "components/shared/Spinner";

import MailingsEdit from "../../modals/MailingsEdit";
import MailingsList from "./MailingsList";

const MailingsMain = () => {
	const alert                             = useAlert();
	const [showEdit, setShowEdit]           = useState(false);
	const [mails, setMails]                 = useState([]);
	const [mail, setMail]                   = useState(null);
	const [ready, setReady]                 = useState(false);

	const load = useCallback(() => {
		axios.get("/cv-mailings").then(response => {
			setMails(response.data);
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden der Mailings: ${error}`));
	}, [alert]);

	useEffect(() => {
		load();
	}, [load]);

	return (
		<>
			<header style={{marginBottom: "2rem"}}>
				<h2 className = "py-4">Mitglieder Mailings <button className = "btn btn-success btn-sm float-right"	onClick={() => setShowEdit(true)}>
					<PersonPlusFill className = "big" / > Neue Nachricht verfassen </button>
				</h2>
			</header>
			<section className="pills">
				<div className="btn-group">
					<NavLink end to="/ClubManagement/mailings" className={({isActive}) => `btn btn-outline-primary${isActive ? " active" : ""}`}>Nachrichten</NavLink>
					<NavLink end to="/ClubManagement/mailings/archive" className={({isActive}) => `btn btn-outline-primary${isActive ? " active" : ""}`}>Archive</NavLink>
				</div>	
			</section>
			{ready ?
				<Routes>
					<Route path="/" element={<MailingsList mails={mails} mail={mail} setMail={setMail} load={load} />} />
					<Route exact path="archive" element={<MailingsList mails={mails} mail={mail} setMail={setMail} load={load} archive={true} />} />
				</Routes>
				: <Spinner />
			}
			<MailingsEdit show={showEdit} setShow={setShowEdit} callback={() => load()} />
		</>
	);
};

export default MailingsMain;