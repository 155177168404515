import React from "react";

import {Save}     from "react-bootstrap-icons";

import Spinner          from "components/shared/Spinner";
import CurrencySelect   from "components/shared/CurrencySelect";

const InvoiceSettings = ({formData, save, handleInput, settingsChanged}) => {

    return (
        <>
            <header>
                <h2 className="py-4">
                    Rechnung
                    <button className="btn btn-success btn-sm float-right" disabled={!settingsChanged} onClick={settingsChanged ? event => save(event) : null}>
						<Save /> Speichern
                    </button>
                </h2>
            </header>
			{formData ? 
            <>
				<section className="grid half-half">
                    <div className="field-wrapper">
                        <label htmlFor="invoiceFirstName">invoiceFirstName</label>
                        <input type="text" className="form-control" name="invoiceFirstName" value={formData.invoiceFirstName} onChange={event => handleInput(event)} />
                    </div>
                    <div className="field-wrapper">
                        <label htmlFor="invoiceLastName">invoiceLastName</label>
                        <input type="text" className="form-control" name="invoiceLastName" value={formData.invoiceLastName} onChange={event => handleInput(event)} />
                    </div>
                    <div className="field-wrapper">
                        <label htmlFor="invoiceEmail">invoiceEmail</label>
                        <input type="text" className="form-control" name="invoiceEmail" value={formData.invoiceEmail} onChange={event => handleInput(event)} />
                    </div>
                    <div className="field-wrapper">
                        <label htmlFor="invoicePhone">invoicePhone</label>
                        <input type="text" className="form-control" name="invoicePhone" value={formData.invoicePhone} onChange={event => handleInput(event)} />
                    </div>
                    <div className="field-wrapper">
                        <label htmlFor="invoiceStreet">invoiceStreet</label>
                        <input type="text" className="form-control" name="invoiceStreet" value={formData.invoiceStreet} onChange={event => handleInput(event)} />
                    </div>
                    <div className="field-wrapper">
                        <label htmlFor="invoiceAdminArea2">invoiceAdminArea2</label>
                        <input type="text" className="form-control" name="invoiceAdminArea2" value={formData.invoiceAdminArea2} onChange={event => handleInput(event)} />
                    </div>
                    <div className="grid half-half" style={{padding: "0"}}>
                        <div className="field-wrapper">
                            <label htmlFor="taxID">taxID</label>
                            <input type="text" className="form-control" name="taxID" value={formData.taxID} onChange={event => handleInput(event)} />
                        </div>
                        <div className="field-wrapper">
                            <label htmlFor="defaultDueDays">defaultDueDays</label>
                            <input type="number" className="form-control" name="defaultDueDays" value={formData.defaultDueDays} onChange={event => handleInput(event)} />
                        </div>
						<div className="field-wrapper">
							<label htmlFor="defaultDueDays">customNote</label>
							<input type="text" className="form-control" name="customNote" value={formData.customNote} onChange={event => handleInput(event)} />
						</div>
                    </div>
                    <div className="grid half-half" style={{padding: "0"}}>
                        <div className="field-wrapper">
                            <label htmlFor="invoiceCurrency">invoiceCurrency</label> <br />
                            <CurrencySelect value={formData.invoiceCurrency} onChange={event => handleInput(event)} name="invoiceCurrency" style={{
							    width: "100%",
								height: "calc(1.5em + 0.75rem + 2px)",
								padding: "0.375rem 0.75rem"
						    }}/>
                        </div>
                        <div className="field-wrapper">
                            <label htmlFor="invoiceNumber">invoiceNumber</label>
                            <input type="number" className="form-control" name="invoiceNumber" value={formData.invoiceNumber} onChange={event => handleInput(event)} />
                        </div>
                    </div>
				</section>
            </>
			: <Spinner />}
        </>
    );
};

export default InvoiceSettings;