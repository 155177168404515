import React from "react";

import {Modal} from "react-bootstrap";

const PaymentSendReport = ({report, show, setShow}) => {
	if (!report)
		return <></>

	return (
		<Modal size="xl" onHide={() => setShow(false)} show={show}>
			<Modal.Header>
				<Modal.Title>Report Rechnungsversand</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<h5 className="py-3">
						Erfolgreich {report.succeeded.length}, Fehlgeschlagen: {report.failed.length}
					</h5>
				</div>
				{report.failed.length > 0 ?
					<section className="alert alert-danger">
						<h3 className="text-danger">Fehlgeschlagen</h3>
						<hr />
						{report.failed.map((payment, index) => {
							const member = payment.payment.cv_member;

							return (
								<details key={index} className="text-dark">
									<summary>{member.lastName}, {member.firstName} &lt;{member.email}&gt;</summary>
									<p className="px-3">
										{payment.data?.details?.map((issue, index) => {
											const fields = issue.field?.split("/") || [];
											const field  = fields[fields.length - 1];

											return (
												<p title={issue.description} style={{cursor : "pointer"}}>
													<b>Feld:</b> <code className="text-secondary">{field}</code>, <b>Wert:</b> &laquo; <code className="text-secondary">{issue.value}</code> &raquo;
												</p>
											);
										})}
									</p>
									<hr />
								</details>
							);
						})}
					</section>
				: null}
				{report.succeeded.length > 0 ?
					<section className="alert alert-success">
						<h3 className="text-success">Erfolgreich</h3>
						<hr />
						{report.succeeded.map((payment, index) => {
							const member = payment.cv_member;

							return (
								<p key={index}>
									<b>{member.lastName}, {member.firstName}</b> &lt;{member.email}&gt;
								</p>
							);
						})}
					</section>
				: null}
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-primary" onClick={() => setShow(false)}>OK</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PaymentSendReport;