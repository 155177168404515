import React, {useState, useEffect} from "react";
import axios from "axios";

import {Modal, Button} from "react-bootstrap";
import {useAlert}      from "react-alert";
import {PersonPlusFill, Trash, Pencil, InfoCircle, PersonCheckFill, FileEarmarkArrowDown} from "react-bootstrap-icons";

import SortableTable  from "../../../components/shared/SortableTable";
import Spinner        from "../../../components/shared/Spinner";
import EmailFormatter from "../../../formatters/EmailFormatter";
import SearchField    from "components/shared/SearchField";

import EditScreen      from "./modals/EditScreen";
import InfoScreen      from "./modals/InfoScreen";
import ProposalsReport from "./reports/ProposalsReport";


const NewProposal = () => {
    const headers = [
        {
            label: "Nachname",
            member : "lastName"
        },
        {
            label: "Vorname",
            member : "firstName"
        },
        {
            label: "Organisation",
            member : "company"
        },
        {
            label: "E-Mail",
            member: "email",
            formatter : EmailFormatter
        },
        {
            label: "Membership",
            member: "cv_plan.Name"
        },
        {
            label: "",
            member: null,
            sortable: false,
            width: '200px',
            functional : ({member}) => {
                return (
                    <>
                        <button className="btn" onClick={() => {showMemberInfoPrepare(member)}}><InfoCircle style={{margin : 0}} className="svg-show" /></button>
                        <button className="btn" onClick={() => {acceptUser(member)}}><PersonCheckFill /></button>
                        <button className="btn" onClick={() => {setUser(member); setShowEdit(true);}}><Pencil /></button>
                        <button className="btn" onClick={() => {delUserPrepare(member)}}><Trash /></button>
                    </>
                )
            }
        }
    ];

    const [users, setUsers]                   = useState([]);
    const [user, setUser]                     = useState(null);
    const [loading, setLoading]               = useState(true);
    const [modal, setModal]                   = useState(false);
    const [modalText, setModalText]           = useState("");
    const [userToDelete, setUserToDelete]     = useState(null);
    const [showEdit, setShowEdit]             = useState(false);
    const [showInfo, setShowInfo]             = useState(false);
    const [search, setSearch]                 = useState("");
    const [filteredUsers, setFilteredUsers]   = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentPlans, setPaymentPlans]     = useState([])
    const [tags, setTags]                     = useState([])
    const handleModalClose                    = (setter) => setter(false);
    const alert                               = useAlert();

    useEffect(() => {
        load();
    },[]);

    /**
     * Freetext Filter/Search
     */
    useEffect(() => {
        if(search === "")
            setFilteredUsers(users);
        else {
            setFilteredUsers(users.filter(user => {
                const keyword = search.toLowerCase();

                if (
                    user?.firstName?.toLowerCase().indexOf(keyword)               >= 0 ||
                    user?.lastName?.toLowerCase().indexOf(keyword)                >= 0 ||
                    user?.company?.toLowerCase().indexOf(keyword)                 >= 0 ||
                    user?.email?.toLowerCase().indexOf(keyword)                   >= 0 ||
                    user?.cv_plan?.Name?.toLowerCase().indexOf(keyword)           >= 0
                ) {
                    return user;
                }

                return false;
            }));
        }
    }, [users, search]);

    useEffect(() =>  setFilteredUsers(users), [users]);

    /*** Clean Up ***/
    useEffect(() => () => null, [users, search]);

    const load = () => {
        axios.get("/members?accepted=false&_limit=-1").then(response => {
            //console.log("members: ", response.data);
            const members = response.data.map(member => member.hasOwnProperty("cv_plan") && member.cv_plan !== null? member : {...member,cv_plan:{Name: " "}})
            console.log(members);
            setUsers(members);
            setLoading(false);
        }).catch(error => console.log("members fetch: " + error));

        axios.get("/cv-payment-methods").then(response => {
            //console.log("cv-payment-methods", response.data);
            setPaymentMethods(response.data);
        }).catch(error => console.log("paymentmethods fetch: " + error));

        axios.get("/cv-plans").then(response => {
            console.log("cv-plans", response.data);
            setPaymentPlans(response.data);
        }).catch(error => console.log("paymentplans fetch: " + error));

        axios.get("/cv-tags").then(response => {
            //console.log("cv-tags", response.data);
            let tags = [];
            response.data.forEach((tag, index) => {
                tags.push({id: index, name: tag.Tag});
            })
            setTags(tags);
        }).catch(error => console.log("tags fetch: " + error));
    }


    /*** Delete USER ***/
    const delUserPrepare = member => {
        setModalText(`Sind Sie sicher, dass Sie den Benutzer ${member?.firstName} ${member?.lastName} löschen wollen?`);
        setUserToDelete(member.id);
        setModal(true);
        //console.log("delUserPrepare");
    }

    const delUser = () => {
        //console.log("delUser");
        if (userToDelete === null) {
            setModal(false);
            return;
        }
        axios.delete(`/members/${userToDelete}`)
            .then(response => {
                let newUsers = users.filter(item => {
                    return item._id !== userToDelete;
                });
                setModal(false);
                setUserToDelete(null);
                setUsers(newUsers);
                alert.success('Das Mitglied wurde erfolgreich gelöscht!');
            })
            .catch(error => {
                console.error(error);
                alert.error('Es ist ein Fehler entstanden!');
            });
    }


    /*** Accept a user-membership ***/
    const acceptUser = async (userData) => {
        //console.log("Accept ", userData);
        axios.put(`/members/${userData.id}`, {
            ...userData,
            accepted:true,
        }).then(response => {
            //console.log(response);
            setLoading(true);
            setShowEdit(false);
            load();
            alert.success('Das Mitglied wurde erfolgreich akzeptiert!');
        }).catch(error=>{
            console.error(error);
        });
    }

    /*** Show Member Info Modal ***/
    const showMemberInfoPrepare = member => {
        setUser(member);
        setShowInfo(true);
    }

    return(
        <>
            <header style={{marginBottom : "2rem"}}>
                <h2 className="py-4">
                    Offene Anträge
                    <button className="btn btn-success btn-sm float-right" onClick={() => {setUser(null); setShowEdit(true);}}>
                        <PersonPlusFill className="big" /> neuen Antrag einreichen
                    </button>
                </h2>
                <section className="grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />
					<section>
						<button	className="btn btn-outline-primary" onClick={() => ProposalsReport(users, {})}>
							<FileEarmarkArrowDown />
							PDF Export
						</button>
					</section>
                </section>
            </header>
			{loading ?
				<Spinner />
			: (
				<>
					<SortableTable headers={headers} data={filteredUsers}  />
					<EditScreen show={showEdit} setShow={setShowEdit} currentUser={user} paymentMethods={paymentMethods} paymentPlans={paymentPlans} allTags={tags} callback={() => load()} />
					<InfoScreen show={showInfo} setShow={setShowInfo} currentUser={user} onHide={() => handleModalClose(setShowInfo)}/>
					<Modal show={modal} onHide={() => handleModalClose(setModal)}>
						<Modal.Header closeButton>
							<Modal.Title>Löschen</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{modalText}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="outline-danger" onClick={() => {setUserToDelete(null); setModal(false);}}>Abbrechen</Button>
							<Button variant="primary" onClick={delUser}>OK</Button>
						</Modal.Footer>
					</Modal>
				</>
			)}
        </>
    );
};

export default NewProposal;
