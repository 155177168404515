import React, {useState, useEffect, useMemo, useCallback} from "react";
import axios from "axios";

import {InfoCircleFill, ArchiveFill} from "react-bootstrap-icons";
import {useAlert} from "react-alert";

import SortableTable    from "components/shared/SortableTable";
import SearchField      from "components/shared/SearchField";
import DateFormatter    from "formatters/DateFormatter";
import YesNoModal       from "components/shared/YesNoModal";

import MailInfo from "../../modals/MailInfo";

const MailingsList = ({mails, mail, setMail, load, archive = false}) => {
	const alert                             = useAlert();
	const [showInfo, setShowInfo]           = useState(false);
	const [search, setSearch]               = useState("");
	const [filteredMails, setFilteredMails] = useState([]);
	const [mailing, setMailing]             = useState(null);
	const [showArchive, setShowArchive]     = useState(false);

	const relevantMailings = useMemo(() => {
		return mails.filter(mail => mail.archived === archive);
	}, [mails, archive]);

	const sendToArchive = useCallback(() => {
		setShowArchive(false);

		if (!mailing)
			return;
		const newMaling = {
			...mailing,
			archived : true
		};
		axios.put(`/cv-mailings/${mailing._id}`, newMaling).then(() => {
			setMailing(null);
			load();
			alert.success(`Mailing '${mailing.subject}' wurde archiviert!`);
		}).catch(error => alert.error(`Fehler beim Archivieren des Mailings: ${error}`));
	}, [alert, load, mailing]);

	const headers = useMemo(() => [
		{
			label     : "Datum",
			member    : "date_sent",
			width     : "10%",
			formatter : DateFormatter
		},
		{
			label  : "Betreff",
			member : "subject",
		},
		{
			label     : "An alle?",
			member    : "isToAll",
			padding   : "0.8rem",
			sortable  : false,
			width     : "10%",
			formatter : value => value === "true" ? "Ja" : "Nein"
		},
		{
			label : "",
			member : null,
			sortable : false,
			width: "90px",
			functional : ({member}) => {
				return (
					<>
						<button className="btn" onClick={() => {setMail(member); setShowInfo(true);}}><InfoCircleFill /></button>
						{!archive ?
							<button className="btn" title="archivieren" onClick={() => {setMailing(member); setShowArchive(true)}}><ArchiveFill /></button>
							: null
						}	
					</>
				);
			}
		}
	], [setMail, archive]);

	/**
	 * Searchfilter
	 */
	useEffect(() => {
		if (search === "") {
			setFilteredMails(relevantMailings);

			return;
		}
		const regex = new RegExp(`.*${search}.*`, "gi");
		setFilteredMails(relevantMailings.filter(mail => {
			return mail.message?.match(regex) || mail.subject?.match(regex);
		}));
	}, [relevantMailings, search]);

	return (
		<>
			<section className="filter grid three-thirds">
				<SearchField search={search} setSearch={setSearch} />
			</section>
			<SortableTable headers={headers} data={filteredMails} /> 
			<MailInfo show={showInfo} setShow={setShowInfo} mail={mail} />
			{!archive ?
				<YesNoModal title="Mailing archivieren" text={<>Möchten Sie das Mailing {mailing?.subject} wirklich archivieren?<p className="description"><i>(dieser Vorgang kann nicht rückgänig gemacht werden)</i></p></>} show={showArchive} setShow={setShowArchive} callback={sendToArchive} />
				: null
			}
		</>
	);
};

export default MailingsList;