import React from "react";

import {Save}     from "react-bootstrap-icons";
import {Form}     from "react-bootstrap"

import Spinner from "components/shared/Spinner";

const TechnicalSettings = ({formData, save, handleInput, settingsChanged}) => {
    return (
        <>
            <header>
                <h2 className="py-4">
                    Technisches / PayPal
                    <button className="btn btn-success btn-sm float-right" disabled={!settingsChanged} onClick={settingsChanged ? event => save(event) : null}>
						<Save /> Speichern
                    </button>
                </h2>
            </header>
			{formData ? 
            <>
				<section className="grid small-gap">
                    <div className="field-wrapper">
                        <label htmlFor="clientID">clientID</label>
                        <input type="text" className="form-control" name="clientID" value={formData.clientID} onChange={event => handleInput(event)}/>
                    </div>
                    <div className="field-wrapper">
                        <label htmlFor="secret">secret</label>
                        <input type="text" className="form-control" name="secret" value={formData.secret} onChange={event => handleInput(event)} />
                    </div>
                    
                    <h4 style={{marginTop: "1em"}}>Sandbox Einstellungen</h4>
                        <Form.Check type="switch" name="sandbox" onChange={event => handleInput(event)} checked={formData?.sandbox || 0} label="Sandbox" id="active" />
                    <div className="field-wrapper">
                        <label htmlFor="sandboxAccount">sandboxAccount</label>
                        <input type="text" className="form-control" name="sandboxAccount" value={formData.sandboxAccount} onChange={event => handleInput(event)} />
                    </div>
                    <div className="field-wrapper">
                        <label htmlFor="sandboxClientID">sandboxClientID</label>
                        <input type="text" className="form-control" name="sandboxClientID" value={formData.sandboxClientID} onChange={event => handleInput(event)} />
                    </div>
                    <div className="field-wrapper">
                        <label htmlFor="sandboxSecret">sandboxSecret</label>
                        <input type="text" className="form-control" name="sandboxSecret" value={formData.sandboxSecret} onChange={event => handleInput(event)} />
                    </div>
				</section>
            </>
			: <Spinner />}
        </>
    );
};

export default TechnicalSettings;