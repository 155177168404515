import React from "react";

import Currencies from "utils/Currencies";

const CurrencySelect = ({value, onChange, ...props}) => {
	return (
		<select className="form-control" value={value} onChange={onChange} {...props} required>
			<option value="" disabled>Währung auswählen...</option>
			{Currencies.map((currency, index) => {
				return <option key={index} value={currency}>{new Intl.DisplayNames(["de"], {type : "currency"}).of(currency)}</option>
			})}
		</select>
	);
};

export default CurrencySelect;
