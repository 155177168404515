import axios from "axios";
import moment from "moment";

const createInvoice = async (setLoading, user, alert) => {

    let shouldCreate = true;

    const loadInvoices = async () => {
        let now = moment();
        await axios.get(`/cm-zahlungens?cv_member=${user.id}`)
            .then(response => {
                response.data.forEach(invoice => {
                    if (now.diff(moment(invoice.createdAt), "months") < 11) {
                        shouldCreate = false;
                        alert.error(`Die letzte Rechnung von ${invoice.cv_member.firstName} ${invoice.cv_member.lastName} ist jünger als 1 Jahr!`);
                    }
                });
            })
            .catch(error => console.error(error));
    }

    const postInvoices = () => {
        if(shouldCreate) {
            return axios.post("/cm-zahlungens", {
                amount: user?.cv_plan?.Price,
                cv_plan: user?.cv_plan,
                cv_payment_method: user?.cv_payment_method,
                cv_member: user?.id,
                payed: false
            }).then(response => {
                alert.success(`Neue Rechnung ${user.firstName} ${user.lastName} für wurde erstellt!`);
            }).catch(error => {
                console.error(error.response);
                alert.error('Es gab ein Problem beim Erstellen der Rechnung!');
            });
        }
    }

    const init = async () => {
        setLoading(true);
        await loadInvoices();
        await postInvoices();
        setLoading(false);
    }

    await init();
}

export default createInvoice;
