import React from "react";

import {Modal} from "react-bootstrap";
import Output  from "editorjs-react-renderer";

import {StringTimeFormatter} from "formatters/DateFormatter";

const MailInfo = ({mail, show, setShow}) => {
	return (
		<Modal size="xl" onHide={() => setShow(false)} show={show}>
			<Modal.Header>
				<Modal.Title>
					Mailing {mail?.isToAll ? <i className="description">(an alle Mitglieder)</i> : null}
					<br />
					<small style={{fontWeight : 100, fontSize : "0.8rem"}}>{StringTimeFormatter(mail?.date_sent)}</small>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{padding : "2rem"}}>
				<p>
					<b>Betreff:</b> {mail ? mail.subject : "–"}
				</p>
				{mail ?
					<Output data={JSON.parse(mail.message)} />
					: null
				}
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => setShow(false)}>schließen</button>
			</Modal.Footer>
		</Modal>
	);
};

export default MailInfo;