import PDF, {Align} from "../../../../utils/PDF";

const ExportType = {
	ALL       : 0,
	PAYED     : 1,
	NOT_PAYED : 2
}

const PaymentsReport = (payments, type = ExportType.ALL, user = null) => {
	const doc = new PDF({
		top    : 20,
		right  : 15,
		left   : 15,
		bottom : 15
	});

	doc.h1("marvolo. Zahlungsexport", Align.CENTER, 0);

	if(user) {
		doc.heading(
			`${user.firstName} ${user.lastName}, ${user.aka_title}`,
			Align.CENTER,
			1,
			8
		);
	} else {
		doc.heading(
			type === ExportType.PAYED ? "(nur Bezahlte)" : (type === ExportType.NOT_PAYED ? "(nur Ausstehende)" : "(Alle Zahlungen)"),
			Align.CENTER,
			1,
			8
		);
	}

	doc.vspace(10).tabular({
		head : [[
			"Vorname",
			"Nachname",
			"E-Mail",
			"Betrag",
			"Zahlart",
			"Zahlungsstatus"
		]],
		body: payments.map(payment => {
			return [
				payment.cv_member.firstName,
				payment.cv_member.lastName,
				payment.cv_member.email,
				`${(Math.round(payment.cv_plan.Price * 100) / 100).toLocaleString("de-DE", {minimumFractionDigits : 2})} EUR`,
				payment.cv_payment_method.Name,
				payment.payed ? "gezahlt" : "nicht gezahlt"
			];
		}),
		styles : {
			fontSize : 8
		}
	});

	doc.show("marvolo-zahlungsexport.pdf");
};

export {ExportType};
export default PaymentsReport;
