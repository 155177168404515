import React, {useState, useRef, useEffect, useMemo} from "react";
import axios from "axios";

import {Modal}    from "react-bootstrap";
import {useAlert} from "react-alert";

import Output                 from "editorjs-react-renderer";
import {createReactEditorJS}  from "react-editor-js";
import list                   from "@editorjs/list";
import header                 from "@editorjs/header"
import paragraph              from "@editorjs/paragraph";

import {StrapiDate} from "utils/StrapiUtils";

import Select from "components/shared/Select";

const EditorJS = createReactEditorJS();

const EditorJSDefaultBlock = {
		time   : Date.now(),
		blocks : [
			{
				type : "paragraph",
				data : {
					text  : "Sehr geehrte Mitglieder,"
				}
			},
			{
				type : "paragraph",
				data : {
					text  : "..."
				}
			}
		],
		version : "2.18.0"
}

const MailingsEdit = ({show, setShow, callback = null}) => {
	const alert                       = useAlert();
	const editor                      = useRef();
    const [message, setMessage]       = useState(EditorJSDefaultBlock);
    const [subject, setSubject]       = useState("");
	const [signatures, setSignatures] = useState([]);
	const [signature, setSignature]   = useState("");

	const signatureContent = useMemo(() => {
		const content = signatures.filter(item => item._id === signature);

		return content.length > 0 ? content[0].content : "";
	}, [signature, signatures]);

	const send = async () => {
		if (!signature || signature === "") {
			alert.error("Sie haben keine Signatur ausgewählt.");
			return;
		}
		if (!subject || subject === "") {
			alert.error("Sie haben keinen Betreff angegeben.");
			return;
		}
		const message   = await editor.current.save();
		const date_sent = new StrapiDate().toStrapiDate();
		const data      = {
			subject,
			message,
			signature,
			date_sent,
			isToAll : true
		};
		axios.post("/cv-mailings/sendmails", data).then(response => {
			alert.success(`Die Nachricht mit dem Betreff "${data.subject}" wurde erfolgreich versandt.`);
			setMessage({...EditorJSDefaultBlock});
			setSignature("");
			if (callback)
				callback();
			setSubject("");
			setShow(false);
		}).catch(error => {
			console.error("sendMail Error: ", error.response);
			alert.error(`Fehler beim Versenden der Nachricht: ${error.message}`);
		});
	}

	useEffect(() => {
		axios.get("/cv-signatures").then(response => {
			setSignatures(response.data);
		}).catch(error => alert.error(`Fehler beim Laden der Signaturen: ${error}`));
	}, [alert]);

    return (
        <Modal size="lg" scrollable={true} show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Neue Nachricht an alle Mitglieder senden
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <section className="row">
                    <input type="text" onChange={event => setSubject(event.target.value)} className="form-control p-4 mb-2" value={subject} placeholder="Betreff" />
                </section>
                <section className="row editor border-secondary">
					<EditorJS data={message} onInitialize={instance => editor.current = instance} tools={{
						list : {class : list},
						paragraph : {
							class         : paragraph,
							inlineToolbar : true
						},
						header
					}} />
                </section>
				{signature ?
					<section className="signature">
						<Output data={JSON.parse(signatureContent)} />
					</section>
					: ""
				}
				<section className="row">
					<Select data={signatures} defaultText="Signatur auswählen" current={signature} onChange={event => setSignature(event.target.value)} />
				</section>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" onClick={() => setShow(false)}>Abbrechen</button>
                <button className="btn btn-success" onClick={send}>Senden</button>
            </Modal.Footer>
        </Modal>
    );
};

export default MailingsEdit;
